<template>
  <div>
    <Assistant_teacher_bar />
    <v-container fluid>
      <base-material-card
        icon="mdi-account-star"
        title="ข้อมูลครูผู้ช่วย"
        class="px-5 py-3"
      >
        <v-row>
          <v-col cols="12" class="text-center">
            ทั้งหมด
            <span class="font-weight-bold mr-2"> {{ countData.countAll }} </span
            >คน ครั้งที่ 1<span class="font-weight-bold">
              [ {{ countData.countOne }} ] </span
            >คน ครั้งที่ 2
            <span class="font-weight-bold"> [ {{ countData.countTwo }} ] </span
            >คน ครั้งที่ 3
            <span class="font-weight-bold">
              [ {{ countData.countThree }} ] </span
            >คน ครั้งที่ 4<span class="font-weight-bold">
              [ {{ countData.countFour }} ] </span
            >คน ไม่ได้เริ่มประเมิน<span class="font-weight-bold">
              [ {{ countData.countNull }} ] คน</span
            >
            <div class="font-weight-bold red--text">
              * ข้อมูลตั้งแต่วันที่ 1 มกราคม 2566
            </div>
          </v-col>
        </v-row>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="manage_assistant_teachers"
          :search="search"
          group-by="mt_date_app_now"
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
          </template>
          <template v-slot:group.header="{ items, isOpen, toggle }">
            <th colspan="24">
              <v-icon @click="toggle"
                >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
              </v-icon>
              {{
                items[0].mt_date_app_now
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </th>
          </template>

          <template v-slot:item.mt_date_app_postitionnow="{ item }">
            {{
              item.mt_date_app_postitionnow
                | moment("add", "543 year")
                | moment("D MMMM YYYY")
            }}
          </template>

          
          <template v-slot:item.cancelAlert="{ item }">
          <v-icon v-if="item.cancelAlert==='cancel'" color="warning" to="/admin/assistant_teacher_detail">mdi-alert</v-icon>
          </template>


          <template v-slot:item.mt_status="{ item }">
            <v-chip dark color="red" v-if="item.mt_status === 'disclaim'">
              <v-icon dark color="black">mdi-lightbulb-outline</v-icon>
            </v-chip>
            <v-chip dark color="green" v-else>
              <v-icon>mdi-lightbulb-on-outline</v-icon>
            </v-chip>
          </template>

          <template v-slot:item.mt_examtype="{ item }">
            <v-chip dark color="purple" v-if="item.mt_examtype === 'select'">
              คัดเลือก
            </v-chip>
            <v-chip dark color="indigo" v-else>
              ทั่วไป
            </v-chip>
          </template>

          <template v-slot:item.mt_examgroup="{ item }">
            <div class="text-center">
              <v-chip
                dark
                color="purple"
                v-if="item.mt_examgroup === 'general'"
              >
                ทั่วไป
              </v-chip>
              <v-chip dark color="indigo" v-else>
                จชต.
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'transfer_position'"
              >
                ครูโอนย้าย
              </v-chip>

              <v-chip
                dark
                color="warning"
                v-if="item.mt_type_personnel === 'local_dev'"
              >
                ครูพัฒนาท้องถิ่น
              </v-chip>
            </div>
          </template>

          <template v-slot:item.mt_admissions_status="{ item }">
            <v-icon
              v-if="item.mt_admissions_status === 'receipt'"
              color="green"
            >
              mdi-email-open
            </v-icon>
            <v-icon
              v-else-if="item.mt_admissions_status === 'missing'"
              color="red"
            >
              mdi-information
            </v-icon>

            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' &&
                  item.mt_admissions_status !== 'missing'
              "
              color="warning"
              @click.stop="manage_assistant_teacherEdit(item.mt_id)"
            >
              mdi-pencil
            </v-icon>

            <v-icon v-else dark color="warning">mdi-cube-send</v-icon>
          </template>
          <template v-slot:item.action_s="{ item }">
            <v-icon
              v-if="
                item.mt_admissions_status !== 'send' &&
                  item.mt_admissions_status !== 'receipt' &&
                  item.mt_admissions_status !== 'missing'
              "
              color="red"
              @click.stop="manage_assistant_teacherDelete(item.mt_id)"
            >
              mdi-delete
            </v-icon>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>
    </v-container>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Assistant_teacher_bar from "../../components/admin/assistant_teacher_bar.vue";

export default {
  name: "HRvecManageAssistantTeacher",
  components: { Assistant_teacher_bar },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      valid: true,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      manage_assistant_teachers: [],
      search: "",
      pagination: {},
      headers: [
        { text: "วันที่บรรจุ", align: "left", value: "mt_date_app_now" },
        {
          text: "วันที่บรรจุ",
          align: "left",
          value: "mt_date_app_postitionnow"
        },
        { text: "วิทยาลัย", align: "left", value: "college_code" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "วิธีการ", align: "left", value: "mt_examtype" },
        { text: "ประเภทกลุ่ม", align: "left", value: "mt_examgroup" },
        { text: "ประเมินครั้งล่าสุด", align: "left", value: "maxTime" },
        { text: "แจ้งแก้ไข", align: "left", value: "cancelAlert" },
        { text: "รหัสบัตร", align: "left", value: "mt_id_card" },
        { text: "สาขาวิชา", align: "left", value: "mt_id_branch" },
        { text: "ชื่อ-นามสกุล", align: "left", value: "frist_names" },
        { text: "วุฒิการศึกษา", align: "left", value: "mt_ed_abb" },
        { text: "เอกวิชา", align: "left", value: "mt_ed_name" },
        { text: "คำสั่ง", align: "left", value: "mt_order_app" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],

      countData: []
    };
  },
  async mounted() {
    await this.manage_assistant_teacherQueryAll();
  },
  methods: {
    async manage_assistant_teacherQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("manage_assistant_teacher.php", {
          ApiKey: this.ApiKey,
          showall: "ok"
        })
        .finally(() => (this.loading = false));
      this.manage_assistant_teachers = result.data;
      let data = this.manage_assistant_teachers;
      let countAll = 0;
      let countOne = 0;
      let countTwo = 0;
      let countThree = 0;
      let countFour = 0;
      let countNull = 0;
      data.forEach(value => {
        countAll += 1;
        if (value.maxTime == 1) {
          countOne += 1;
        } else if (value.maxTime == 2) {
          countTwo += 1;
        } else if (value.maxTime == 3) {
          countThree += 1;
        } else if (value.maxTime == 4) {
          countFour += 1;
        } else {
          countNull += 1;
        }
      });
      this.countData.countAll = countAll;
      this.countData.countOne = countOne;
      this.countData.countTwo = countTwo;
      this.countData.countThree = countThree;
      this.countData.countFour = countFour;
      this.countData.countNull = countNull;
    }
  },
  computed: {
    color() {
      return "blue-grey";
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>

<style lang="scss" scoped></style>
